import React from 'react'
import { Link } from 'gatsby'

/**
* Navigation component
**/
class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locationPath: ''
        }
      }
    
    componentDidMount() {
        this.setState({locationPath: window.location.pathname})
    }

    render() {
        let countTags = 1

        return (
            <>
                {this.props.data.map((navItem, i) => {
                    if (this.props.type == "nav") {
                        if (navItem.url.match(/^\s?http(s?)/gi)) {
                            return <div className="col-md-6 col-lg-3" key={i}>
                                <a className={this.props.navClass} href={navItem.url} target="_blank" rel="noopener noreferrer">{navItem.label}</a>
                            </div>
                        } else {
                            return <div className="col-md-6 col-lg-3" key={i}>
                                {
                                    this.state.locationPath == navItem.url
                                        ?<div className={`${this.props.navClass} link--active`} onClick={this.props.toggleNav}>{navItem.label}</div>
                                        :<Link className={this.props.navClass} to={navItem.url} activeClassName="link--active">{navItem.label}</Link>
                                }
                            </div>
                        }
                    } else if (this.props.type == "tags") {
                        if (countTags < 12) {
                            if (navItem.node.postCount > 10) {
                                countTags++
                                let navItemUrl = new URL(navItem.node.url);
                                return <div className="col-md-6 col-lg-3" key={'t' + i}>
                                {
                                    this.state.locationPath == navItemUrl.pathname
                                        ?<div className={`${this.props.navClass} link--active`} onClick={this.props.toggleNav}>#{navItem.node.name}</div>
                                        :<Link className={this.props.navClass} to={navItemUrl.pathname}>#{navItem.node.name}</Link>
                                }
                                </div>
                            }
                        }
                    }
                })}
            </>
        )
    }
}

Navigation.defaultProps = {
    navClass: `site-nav-item`,
}

export default Navigation
