import React, { useEffect } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import SVG from 'react-inlinesvg'

import AppsPills from './apps-pills'
import SocialIcons from './SocialIcons'

const Footer = () => {
    let images = useStaticQuery(graphql`{
  bannerEspa: file(relativePath: {eq: "e-bannerespa.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 350, layout: CONSTRAINED)
    }
  }
	bannerAlma: file(relativePath: {eq: "alma-banner.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 350, layout: CONSTRAINED)
    }
  }
}
`)

    useEffect(() => {
        function scrollToTop() {
            var position =
                document.body.scrollTop || document.documentElement.scrollTop;
            if (position) {
                window.scrollBy(0, -Math.max(15, Math.floor(position / 20)));
                var scrollAnimation = setTimeout(scrollToTop, 15);
            } else clearTimeout(scrollAnimation);
        }

        document.getElementById('back_to_top').addEventListener('click', scrollToTop)
        document.getElementById('footer_year').innerText = new Date().getFullYear()
    })

    return (
        <footer>
            <div className="container">
                <div className="footer__top">
                    <div className="row align-items-center">
                        <div className="col-md-4 order-2 order-md-0">
                            <div className="footer__logo">
                                <SVG src='/images/tp-logoblack.svg' />
                                {/* <p className="pl-4">We Build. We Create. We Share.</p> */}
                            </div>
                        </div>
                        <div className="col-md-4 order-1 order-md-0">
                            <button id="back_to_top" className="footer__backtotop d-flex align-items-center mx-auto">
                                <p className="pr-2">Back to Top</p>
                                <SVG src='/images/icons/arrow-up-thin.svg' />
                            </button>
                        </div>
                        <div className="col-md-4 order-3 order-md-0">
                            <AppsPills />
                        </div>
                    </div>
                </div>
                <div className="footer__navigation" role="navigation">
                    <div className="row">
                        <div className="col-6 col-lg">
                            <ul className="unformatted-list">
                                <li className="h6"><Link to="/digital-marketing/">DIGITAL MARKETING</Link></li>
                                <li><Link to="/kataskeyi-istoselidon/">Κατασκευή ιστοσελίδων</Link></li>
                                <li><Link to="/kataskeyi-eshop-me-shopify/">Κατασκευή eshop</Link></li>
                                <li><Link to="/kataskeyi-eshop-me-shopify/">Κατασκευή Shopify</Link></li>
                                <li><Link to="/wordpress-website/">Κατασκευή Wordpress</Link></li>
                                <li><Link to="/social-media-marketing/">Social Media Marketing</Link></li>
                                <li><Link to="/online-marketing/">Online Marketing</Link></li>
                                <li><Link to="/mailchimp-certified-experts-athens-greece/">Email Marketing</Link></li>
                                <li><Link to="/shopify-apps/">Shopify Apps</Link></li>
                                {/* <li><Link to="/proothisi-istoelidon/">Προώθηση Ιστοσελίδων</Link></li> */}
                                {/* <li><Link to="/seo/">SEO</Link></li> */}
                            </ul>
                        </div>
                        <div className="col-6 col-lg">
                            <ul className="unformatted-list">
                                <li className="h6"><Link to="/video-photography/">ΔΗΜΙΟΥΡΓΙΑ ΠΕΡΙΕΧΟΜΕΝΟΥ</Link></li>
                                <li><Link to="/think-videography/">Δημουργία βίντεο</Link></li>
                                <li><Link to="/pro-photography/">Eπαγγελματική φωτογράφιση</Link></li>
                                <li><Link to="/dimiourgia-periexomenou-video/">Δημουργία περιεχομένου βίντεο</Link></li>
                                <li><Link to="/perigrafes-proionton-eshop-9-tropoi/">Περιγραφές προϊόντων E-Shop</Link></li>
                            </ul>
                        </div>
                        <div className="col-6 col-lg">
                            <ul className="unformatted-list">
                                <li className="h6"><Link to="/">ΔΙΑΦΗΜΙΣΤΙΚΕΣ ΥΠΗΡΕΣΙΕΣ</Link></li>
                                <li><Link to="/design-art/">Εταιρική Ταυτότητα</Link></li>
                                <li><Link to="/above-the-line/">Παραγωγή τηλεοπτικού</Link></li>
                                <li><Link to="/media-plan/">Media Plan</Link></li>
                                <li><Link to="/media-buying/">Media Buying</Link></li>
                                <li><Link to="/diafimisi-tileorasi-aksizei/">Διαφήμιση στη τηλεόραση</Link></li>
                                <li><Link to="/facebook-ads/">Διαφημίσεις Facebook</Link></li>
                                <li><Link to="/google-ads-ppc-management/">Διαφημίσεις Google</Link></li>
                            </ul>
                        </div>
                        <div className="col-6 col-lg">
                            <ul className="unformatted-list">
                                <li className="h6"><Link to="/about-us/">DIGITAL AGENCY</Link></li>
                                <li><Link to="/contact-us/">Επικοινωνία</Link></li>
                                <li><Link to="/news/">Τα Νέα μας</Link></li>
                                <li><Link to="/our-work/">Δείγματα εργασιών</Link></li>
                                {/* <li><Link to="/tag/our-work-eshop">Δείγματα Κατασκευής Online Καταστημάτων</Link></li> */}
                                {/* <li><Link to="/tag/our-work-website">Δείγματα Κατασκευής Ιστοσελίδων</Link></li> */}
                                {/* <li><Link to="/tag/our-work-case-studies">Case Studies</Link></li> */}
                                <li><Link to="/our-clients/">Πελάτες</Link></li>
                                <li><Link to="/prosopika-dedomena/">Προσωπικά Δεδομένα</Link></li>
                                <li><Link to="/epidotisi-espa-kataskevi-eshop/">Επιδότηση ΕΣΠΑ E-Shop</Link></li>
                                <li><a href="https://thinkplus.join.com/" title="Find a job position in Think Plus" target="_blank" rel="noopener noreferrer nofollow">We're Hiring!</a></li>
                                {/* <li><Link to="/sxolia-pelatwn/">Σχόλια Πελατών</Link></li> */}
                                {/* <li><Link to="/timokatalagos/">Τιμοκατάλογος</Link></li> */}
                            </ul>
                        </div>
                        <div className="col-6 col-lg">
                            <ul className="unformatted-list">
                                <li className="h6"><Link to="/blog/">TΟ BLOG ΜΑΣ</Link></li>
                                <li><Link to="/covid-19/">Covid-19</Link></li>
                                <li><Link to="/ecommerce-mellon/">Το μέλλον του e-commerce</Link></li>
                                <li><Link to="/chromata-psychologia-branding/">Χρώματα &amp; Ψυχολογία</Link></li>
                                <li><Link to="/online-video-marketing/">Video Marketing</Link></li>
                                <li><Link to="/shopify-i-magento-gia-tin-kataskeyi-eshop-ti-na-dialexo-to-2018/">Shopify ή Magento</Link></li>
                                <li><Link to="/shopify-wordpress-kataskeyh-eshop-nikhths/">Shopify ή Wordpress</Link></li>
                                <li><Link to="/kataskeyi-shopify-eshop-ti-prepei-na-prosexo/">Shopify - Τι πρέπει να προσέξω;</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer__bottom">
                    <div className="row align-items-center">
											<div className="col-12 d-flex flex-wrap align-items-center">
                        <div className="mx-auto mx-lg-0">
                            <SocialIcons type={'theme'} position={'relative'} espa={false} />
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-center mx-auto">
													<a href="/espa-web.pdf" target="_blank" title="Επιχειρησιακό πρόγραμμα">
                            <GatsbyImage
                                image={images.bannerEspa.childImageSharp.gatsbyImageData}
                                alt="ΕΣΠΑ"
                                className="footer__banner--espa m-2" />
													</a>
													<a href="/alma-perigrafi.pdf" target="_blank" title="Επιχειρησιακό πρόγραμμα: ΨΗΦΙΑΚΟ άλμα">
														<GatsbyImage
															image={images.bannerAlma.childImageSharp.gatsbyImageData}
															alt="ΑΛΜΑ ΕΣΠΑ"
															className="footer__banner--espa m-2"
														/>
													</a>
                        </div>
                        <div className="mx-auto mx-lg-0">
                            <p className="footer__copyright">© Copyright 2011 - <span id="footer_year">201*</span>. All Rights Reserved  <br /> Powered by <Link to="/">Think Plus - Creative &amp; Software Agency</Link></p>
                        </div>
											</div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer
