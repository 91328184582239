import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'

import { Navigation } from '.'
import Search from './Search'
import Footer from './footer'
import LocaleSelector from './LocaleSelector'

import '../../styles/app.scss'

/**
* Main layout component
**/
const DefaultLayout = ({ data, children, bodyClass, viewport }) => {
	const site = data.allGhostSettings.edges[0].node
	const tags = data.allGhostTag.edges

	useEffect(() => {
		const SITE_HEAD = document.querySelector('.site-head')

		document.addEventListener('keyup', (e) => {
			if (e.key == "Escape") {
				document.getElementById('site_nav').classList.contains('is-open') && toggleNav()
			}
		})
		document.addEventListener('scroll', () => {
			setTimeout(() => {
				window.pageYOffset > 1 ? SITE_HEAD.classList.add('site-head--backdrop') : SITE_HEAD.classList.remove('site-head--backdrop')
				if (viewport == 'full') {
					window.pageYOffset > 500 ? SITE_HEAD.classList.remove('site-head--transparent') : SITE_HEAD.classList.add('site-head--transparent')
				}
			}, 200)
		})
	})

	let toggleNav = () => {
		let siteNav = document.getElementById('site_nav')
		setTimeout(() => {
			siteNav.classList.toggle('is-open')
			document.querySelector('.site-head').classList.toggle('nav-is-open')
		}, 10)
		siteNav.classList.contains('is-open')
			? setTimeout(() => {
				siteNav.scrollTop = 0
				siteNav.style.display = "none"
			}, 210)
			: siteNav.style.display = "block"
		document.getElementById('site_nav_toggle').classList.toggle('is-pressed')
		siteNav.getAttribute('aria-hidden') == 'true' ? siteNav.setAttribute('aria-hidden', 'false') : siteNav.setAttribute('aria-hidden', 'true')
		siteNav.hidden ? siteNav.hidden = false : siteNav.hidden = true
	};

	return (
		<>
			<Helmet>
				<html lang={site.lang || site.default_locale} />
				<style type="text/css">{`${site.codeinjection_styles}`}</style>
				<script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/6bb59790a0e4d1fef2561b28/script.js" />
				<body className={bodyClass} />
			</Helmet>

			<div className={viewport ? `viewport ${viewport}` : 'viewport'}>
				<div className="viewport-top">
					<header className="site-head">
						<div className="container dec-site-quote">
							<div className="row justify-content-center">
								<div className=" col-6 col-lg-2">
									<Link to="/">
										<div className="site-logo"><p className="visually-hidden">Think Plus</p></div>
									</Link>
								</div>
								<div className="col-6 col-lg-10 d-flex align-items-center justify-content-end">
									<ul className="site__quick-nav horizontal-list">
										<li><Link to="/kataskeyi-istoselidon/" activeClassName="link--active">Web Design</Link></li>
										<li><Link to="/digital-marketing/" activeClassName="link--active">Digital Marketing</Link></li>
										<li><Link to="/video-photography/" activeClassName="link--active">Video &amp; Photography</Link></li>
										<li><Link to="/our-work/" activeClassName="link--active">Our Work</Link></li>
										<li><Link to="/kataskeyi-eshop-me-shopify/" activeClassName="link--active">Shopify</Link></li>
										<li><Link to="/wordpress-website/" activeClassName="link--active">Wordpress</Link></li>
										{/* <li><Link to="/blog/" activeClassName="link--active">Blog</Link></li>
                                    <li><Link to="/news/" activeClassName="link--active">News</Link></li> */}
										<li><Link to="/contact-us/" activeClassName="link--active">Contact Us</Link></li>
									</ul>
									<Search />
									<LocaleSelector />
									<button className="site-nav__toggle" id="site_nav_toggle" onClick={toggleNav} aria-haspopup="true" aria-label="Menu">
										<div className="site-nav__bar"></div>
									</button>
								</div>
							</div>
						</div>
					</header>
					<nav className="site-nav" id="site_nav" aria-label="Main Navigation" aria-hidden="true" hidden>
						<div className="container">
							<div className="row">
								<Navigation data={site.navigation} navClass="site-nav-item" type="nav" toggleNav={toggleNav} />
							</div>
							<div className="row">
								<div className="col">
									<p className="site-nav__title h2">Tags</p>
								</div>
							</div>
							<div className="row">
								<Navigation data={tags} navClass="site-nav-item site-tag-item" type="tags" toggleNav={toggleNav} />
								<div className="col-md-6 col-lg-3">
									<Link className='site-nav-item site-tag-item' to='/fotografisi-eshop-proionta/'>#Shop</Link>
								</div>
							</div>
						</div>
					</nav>

					<main className="site-main">
						{children}
					</main>

				</div>

				<div className="viewport-bottom">
					<Footer />
				</div>
			</div>
		</>
	)
}

const DefaultLayoutSettingsQuery = props => (
	<StaticQuery
		query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                allGhostTag(sort: {order: ASC, fields: name}, filter: {visibility: {ne: "internal"}}) {
                    edges {
                        node {
                            name
                            url
                            postCount
                        }
                    }
                }
            }
        `}
		render={data => <DefaultLayout data={data} {...props} />}
	/>
)

export default DefaultLayoutSettingsQuery
