import React from 'react'
import { Link } from 'gatsby'
import FeatureImage from '../common/FeatureImage'

const PostCard = ({ post, columns }) => {
    const url = `/${post.slug}/`
    let postExcerpt = post.custom_excerpt?post.custom_excerpt:post.excerpt

    let bootstrapColumns = columns?12/columns:6

    postExcerpt = postExcerpt.replace(/\[.*?\]/g, '')

    return (
        <div className={`col-md-${bootstrapColumns}`}>
            <Link to={url} className="post-card">
                <header className="post-card-header">
                    <FeatureImage
                        item={post}
                        figureClass='post-card-image'
                    />
                    {post.featured && <span className="post-card-featured">Featured</span>}
                    <h2 className="post-card-title h4">{post.title}</h2>
                </header>
                <section className="post-card-excerpt">{postExcerpt}</section>
            </Link>
        </div>
    )
}

export default PostCard
