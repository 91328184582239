import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

import '../../styles/components/apps-pills.scss'

const AppsPills = () => {
    let images = useStaticQuery(graphql`{
  skroutz: file(relativePath: {eq: "apps/app-skroutz-xml.png"}) {
    childImageSharp {
      gatsbyImageData(width: 144, quality: 100, layout: CONSTRAINED)
    }
  }
  bestPrice: file(relativePath: {eq: "apps/app-bestprice-xml.png"}) {
    childImageSharp {
      gatsbyImageData(width: 144, quality: 100, layout: CONSTRAINED)
    }
  }
}
`)

    return (
        <div className="apps-pills">
            <Link to="/skroutz-shopify/" className="app-pill d-flex align-items-center">
                <GatsbyImage
                    image={images.skroutz.childImageSharp.gatsbyImageData}
                    alt="Skroutz XML Feed - Shopify App"
                    className="app-pill__icon" />
                <div className="app-pill__name"><strong>Skroutz</strong><p>Shopify App</p></div>
            </Link>
            <Link to="/best-price-shopify/" className="app-pill d-flex align-items-center">
                <GatsbyImage
                    image={images.bestPrice.childImageSharp.gatsbyImageData}
                    alt="Skroutz XML Feed - Shopify App"
                    className="app-pill__icon" />
                <div className="app-pill__name"><strong>BestPrice</strong><p>Shopify App</p></div>
            </Link>
        </div>
    );
}

export default AppsPills